<template>
  <v-card>
    <Loading
      v-if="loading"
      :loading="loading"
      message="Loading Leaderboard"
    />
    <LeaderList
      v-if="leaderboard.length"
      :entry-list="leaderboard"
      :challenge="challenge"
    />
    <v-btn
      v-if="nextPage"
      :block="true"
      @click="getLeaderboard"
    >
      Load More
    </v-btn>
  </v-card>
</template>

<script>
import LeaderList from '@/components/LeaderList.vue'
import Loading from '@/components/Loading.vue'
import { allianceBaseURL, allianceAPI } from '@/api'

export default {
  components: {
    LeaderList,
    Loading,
  },
  props: {
    challenge: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      error: false,
      leaderboard: [],
      loading: false,
      nextPage: null,
    }
  },
  created: function () {
    this.getLeaderboard()
  },
  methods: {
    getLeaderboard() {
      let url = `/quest/challenge/${this.challenge.slug}/leaderboard/`
      if (this.nextPage) {
        url = this.nextPage
      } else {
        this.loading = true
      }
      return allianceAPI.get(url)
        .then((response) => {
          if (this.nextPage) {
            this.leaderboard.push(...response.data.results)
          } else {
            this.leaderboard = response.data.results
          }
          if (response.data.next) {
            this.nextPage = response.data.next.replace(allianceBaseURL, '')
          } else {
            this.nextPage = null
          }
        })
        .finally(() => this.loading = false)
    },
  },
}
</script>
