<template>
  <div class="challenge">
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
    <Loading
      v-if="loading"
      :loading="loading"
      message="Loading Challenge"
    />
    <div v-if="challenge">
      <v-card>
        <v-img
          :src="(challenge.image) ? challenge.image : ''"
          height="200px"
          class="px-3"
        >
          <v-row class="mb-4">
            <v-col>
              <div class="headline">
                {{ challenge.name }}
              </div>
            </v-col>
          </v-row>
          <v-row class="align-end pt-12">
            <v-col>
              <div class="title">
                {{ challenge.points_available }}
              </div>
              <div>
                Points
              </div>
            </v-col>
            <v-col align="right">
              <div class="title">
                {{ challenge.num_likes }}
              </div>
              <div>
                Likes
              </div>
            </v-col>
          </v-row>
        </v-img>
      </v-card>

      <v-row>
        <v-col>
          <Share
            :title="challenge.name"
            class="mr-4"
          />
          <Like
            :object-id="challenge.id"
            app="quest"
            model="challenge"
          />
        </v-col>
      </v-row>

      <div class="section">
        <div class="section-title">
          Dispatch
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="challenge.dispatch" />
        <!--eslint-enable-->
      </div>

      <v-row>
        <v-col>
          <v-card v-if="achievement">
            <v-card-title>My Performance</v-card-title>
            <v-row justify="space-around">
              <v-col
                v-if="achievement.current_level"
                cols="6"
                sm="2"
                class="statistics"
              >
                <div class="stat-value text-h6 text-md-h4">
                  {{ achievement.current_level.display_name }}
                </div>
                <div class="stat-label text-caption">
                  current level
                </div>
              </v-col>
              <v-col
                v-if="achievement.points_earned"
                cols="6"
                sm="2"
                class="statistics"
              >
                <div class="stat-value text-h6 text-md-h4">
                  <animated-integer
                    :value="achievement.points_earned"
                    :animate-on-load="true"
                  />
                </div>
                <div class="stat-label text-caption">
                  points earned
                </div>
              </v-col>
              <v-col
                v-if="achievement.next_level"
                cols="6"
                sm="2"
                class="statistics"
              >
                <div class="stat-value text-h6 text-md-h4">
                  {{ achievement.next_level.display_name }}
                </div>
                <div class="stat-label text-caption">
                  next level
                </div>
              </v-col>
              <v-col
                v-if="progress"
                cols="6"
                sm="2"
                class="statistics"
              >
                <div class="stat-value text-h6 text-md-h4">
                  <animated-integer
                    :value="progress"
                    :animate-on-load="true"
                  />%
                </div>
                <div class="stat-label text-caption">
                  to level up
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab>
          Levels
        </v-tab>
        <v-tab v-if="challenge.show_leaderboard">
          Leaderboard
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item>
          <v-card>
            <v-list three-line>
              <v-list-item
                v-for="level in challenge.challengelevel_set"
                :key="level.id"
              >
                <v-list-item-avatar>
                  <v-img
                    v-if="level.badge_thumbnail_url"
                    :src="level.badge_thumbnail_url"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-row>
                    <v-col class="pr-0">
                      <v-list-item-title v-text="level.display_name" />
                      <v-list-item-subtitle v-text="level.description" />
                    </v-col>
                    <v-col
                      class="text-right pl-0"
                      cols="auto"
                    >
                      <div>
                        {{ level.points }}
                      </div>
                      <div class="points-label">
                        points
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="challenge.show_leaderboard">
          <ChallengeLeaderboard :challenge="challenge" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import AnimatedInteger from '@/components/AnimatedInteger.vue'
import ChallengeLeaderboard from '@/components/ChallengeLeaderboard.vue'
import Like from '@/components/Like.vue'
import Loading from '@/components/Loading.vue'
import Share from '@/components/Share.vue'
import { mapState } from 'vuex'

export default {
  components: {
    AnimatedInteger,
    ChallengeLeaderboard,
    Like,
    Loading,
    Share,
  },
  data: function() {
    return {
      alerts: [],
      challenge: null,
      loading: false,
      loadingError: false,
      tab: null,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    achievement() {
      return this.$store.getters['auth/getChallengeAchievementBySlug'](this.$route.params.slug) || null
    },
    progress() {
      if (this.achievement && this.achievement.score > 0) {
        return (this.achievement.score - Math.floor(this.achievement.score)) * 100
      }
      return 0
    }
  },
  created: function () {
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.loading = true
      return this.$store.dispatch('challenges/getDetails', `/quest/challenge/${this.$route.params.slug}/`)
        .finally(() => {
          this.challenge = this.$store.getters['challenges/getDetailsBySlug'](this.$route.params.slug) || null
          this.loading = false
          if(!this.challenge) {
            this.loadingError = true
            if (!this.$online) {
              this.alerts.push({
                type: 'error',
                message: 'An internet connection is required to download the details of this challenge.',
              })
            } else {
              this.alerts.push({
                type: 'error',
                message: 'Failed to download challenge details.',
              })
            }
          }
        })
    },
  },
}
</script>
